<template>
  <div class="aa-admin">
      <div class="aa-admin-titulo aa-text-h1 text-start px-1 mt-1">
          Administración
        <div class="line"></div>
      </div>
      <BotoneraAdmin />

      <div class="aa-admin-directores my-3">
        <div class="tarjetones">
          <swiper
                :effect="'cards'"
                class="admin-slider"
                :modules="modules"
            >
            <swiper-slide v-for="personal of equipoDirectivo" :key="personal.id">
              <CardEquipo :datos="personal" />
            </swiper-slide>

            </swiper>

        </div>

      </div>
  </div>
</template>

<script>
import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import { EffectCards } from "swiper";
import 'swiper/swiper.min.css'
import 'swiper/modules/effect-cards/effect-cards.min.css'
import useAdministracion from '../composables/useAdministracion'
import { defineAsyncComponent } from '@vue/runtime-core'

export default {
    components: { 
      BotoneraAdmin: defineAsyncComponent( () => import('../components/Administracion/BotoneraAdmin.vue')), 
      CardEquipo: defineAsyncComponent( () => import('../components/Administracion/CardEquipo.vue')), 
      Swiper, 
      SwiperSlide },
    setup() {
      const { equipoDirectivo } = useAdministracion()

      return { 
        modules: [ EffectCards ],
        equipoDirectivo
      }
    }
}
    

</script>

<style lang="sass" scoped>
.aa-admin
    $root:&
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-titulo
        order: 1
        .line
            background-color: #86e4b9
            display: inline-block
            height: 20px
            left: 0
            position: absolute
            top: 37px
            width: 90%
            z-index: -1
    &-directores
      order: 2
      width: 100%
      overflow: hidden
      .tarjetones
        height: 40vh

.swiper
  width: 95vw
  height: 220px
  @include breakpoint(lg)
    width: 65vw
.swiper-slide
  background-color: #fff
  border-radius: 16px

.swiper-slide-next, .swiper-slide-prev, .swiper-slide
  filter: blur(20px)
  opacity: 0.4

.swiper-slide-visible
  filter: none !important
  opacity: 1

</style>